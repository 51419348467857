import { createSlice } from '@reduxjs/toolkit'
import clientService from '../../services/service'
var FileSaver = require('file-saver');

export const reportInitialState = {
  loading: false,
  filter: {
    page: 1,
    size: 100,
    sort_by: '',
    sort_order: 'desc',
    filter_pid: '',
    filter_part_desc: '',
    filter_vendor: ''
  },
  reportsList:[],
  setupList:[],
  reportsReceiving: {
    totalPages: 1,
    currentPage: 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data: [],
  },
  reports: [],
  reportsPID: [],
  reportsUsage: [],
  csvExportData: [],
  notes:[],
  reportLoaded: false,
  avaFilter:{
    page : 1,
    size : 10,
    sort_by: "updated_on",
    sort_order : "desc"
  },
  ptoAvailableData : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  reqFilter:{
    page : 1,
    size : 10,
    sort_by: "created_on",
    sort_order : "desc",
    start_date : "",
    end_date : ""
  },
  ptoRequestData : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  stateFilter : {
    page : 1,
    size : 10,
    sort_by: "created_on",
    sort_order : "asc",
    start_date : "",
    end_date : ""
  },
  ptoStatementData : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  bsFilter:{
    job_id: '',
    filter_category: '',
    filter_cc: '',
    page : 1,
    size : 100,
    sort_by: "budget_id",
    sort_order : "desc",
  },
  balanceSheet : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  hsBPPFilter:{
    job_id: '',
    filter_category: '',
    filter_cc: '',
    page : 1,
    size : 100,
    sort_by: "payPeriodEndDate",
    sort_order : "desc",
  },
  hsBPPData : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  employeeHourlyFilter:{
    page : 1,
    size : 100,
    sort_by: "employee_name",
    sort_order : "ASC",
  },
  employeeHourlyData: {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  lookFilter:{
    page : 1,
    size : 10,
    sort_by: "lastSubmitDate",
    sort_order : "desc"
  },
  lookBranchSummaryData : {
    totalPages : 1,
    currentPage : 1,
    from: 1,
    lastPage: 1,
    perPage: 2,
    to: 20,
    total: 20,
    data : []
  },
  lookSummaryHide : {},
  reminderData: {},
}
let listApiController;

export const reportsSlice = createSlice({
  name: 'reports',
  initialState: reportInitialState,
  reducers: {
    filters: (state, action) => {
        state.filter = action.payload
        state.loader = true
    },
    setNotes: (state, action) => {
      state.notes = action.payload
      state.loader = false
    },
    setReportList: (state, action) => {
        state.reportsList = action.payload.data
        state.loader = false
    },
    setSetuplist: (state, action) => {
        state.setupList = action.payload.data
        state.loader = false
    },
    setReportPID: (state, action) => {
      state.reportsPID = action.payload
      state.loader = false
    },
    setReportUsage: (state, action) => {
      state.reportsUsage = action.payload
      state.loader = false
    },
    setReportReceiving: (state, action) => {
      state.reportsReceiving = action.payload.data
      state.loader = false
    },
    setLoader: (state, action) => {
      state.loader = action.payload
      state.reportLoaded = false
    },
    setCsvExportData: (state, action) => {
      state.csvExportData = action.payload
      state.reportLoaded = true
      state.loader = false
    },
    setPIDCsvExportData: (state, action) => {
      state.csvExportData = action.payload
      state.reportLoaded = true
      state.loader = false
    },
    setReceivingCsvExportData: (state, action) => {
      state.csvExportData = action.payload
      state.reportLoaded = true
      state.loader = false
    },
    avaFilters:(state, action) => {
        state.avaFilter = action.payload;
        state.loader = true;
    },
    setPTOAvailableData: (state, action) => {
      state.ptoAvailableData = action.payload.data
      state.loader = false
    },
    setPTOAvaCsvExportData : (state, action) => {
      state.csvExportData = action.payload;
      state.reportLoaded = true;
      state.loader = false;
    },
    reqFilters:(state, action) => {
        state.reqFilter = action.payload;
        state.loader = true;
    },
    setPTORequestData: (state, action) => {
      state.ptoRequestData = action.payload.data
      state.loader = false
    },
    setPTOReqCsvExportData : (state, action) => {
      state.csvExportData = action.payload;
      state.reportLoaded = true;
      state.loader = false;
    },
    stateFilters:(state, action) => {
      state.stateFilter = action.payload;
      state.loader = true;
    },
    setPTOStatementData: (state, action) => {
      state.ptoStatementData = action.payload.data
      state.loader = false
    },
    setPTOStateCsvExportData: (state, action) => {
      state.csvExportData = action.payload;
      state.reportLoaded = true;
      state.loader = false;
    },
    setBsFilter: (state, action) => {
        state.bsFilter = action.payload
        state.loader = true
    },
    setBalanceSheet: (state, action) => {
        state.balanceSheet = action.payload.data
        state.loader = false
    },
    setHsBPPFilter: (state, action) => {
        state.hsBPPFilter = action.payload
        state.loader = true
    },
    setHsBPPData: (state, action) => {
        state.hsBPPData = action.payload.data
        state.loader = false
    },
    setEmployeeHourlyFilter: (state, action) => {
        state.employeeHourlyFilter = action.payload
        state.loader = false
    },
    setEmployeeHourlyReportData: (state, action) => {
        state.employeeHourlyData = action.payload.data
        state.loader = false
    },
    lookFilters:(state, action) => {
        state.lookFilter = action.payload;
        state.loader = true;
    },
    setLookBranchSummaryData: (state, action) => {
      state.lookBranchSummaryData = action.payload.data
      state.loader = false
    },
    setLookSummaryHide: (state, action) => {
      state.lookSummaryHide = action.payload;
      state.loader = false;
    },
    setSendReminderData: (state, action) => {
      state.reminderData = action.payload;
      state.loader = false;
    },
  },
})

export const getReportList = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('report-list', data)
    dispatch(setReportList(response.data))
}

export const getSetupList = (data) => async (dispatch) => {
    dispatch(setLoader(true))
    const response = await clientService.post('setup-list', data)
    dispatch(setSetuplist(response.data))
}

export const getNotes = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('order/view-note/', data)
  dispatch(setNotes(response.data.data))
}
export const getReportPID = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('report-pid', data)
  dispatch(setReportPID(response.data.data))
}

export const getReportUsage = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  for (var i = 0; i < data?.department_id?.length; i++) {
    if(data.department_id[i] == 'all'){
      data.department_id[i] = -1;
    }
  };
  const response = await clientService.post('report-usage', data)
  dispatch(setReportUsage(response.data.data))
}

export const getReportReceiving = (filter) => async (dispatch) => {
    if (listApiController) {
        listApiController.abort()
    }
    listApiController = new AbortController();
    //dispatch(setLoader(true))
    dispatch(filters(filter))
    let data = {...filter}
    const response = await clientService.post('report-receiving', data, {signal:  listApiController.signal})
    if (response && response.data && response.data.data) {
        dispatch(setReportReceiving(response.data.data))
    }
}

export const reportUsageCSV = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('report-usage/csv', data)
  dispatch(setCsvExportData(response.data.data))
}
export const reportPIDCSV = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('report-pid/csv', data)
  dispatch(setPIDCsvExportData(response.data.data))
}
export const reportReceivingCSV = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('report-receiving/csv', data)
  dispatch(setReceivingCsvExportData(response.data.data))
}

export const getPTOAvailableData = (avaFilter) => async (dispatch) => {
  dispatch(avaFilters(avaFilter));
  dispatch(setLoader(true))
  const response = await clientService.post('report-pto-available', avaFilter)
  dispatch(setPTOAvailableData(response.data.data))
}
export const reportPTOAvaCSV = () => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("report-pto-available/csv");
  dispatch(setPTOAvaCsvExportData(response.data.data));
}
export const getPTORequestData = (reqFilter) => async (dispatch) => {
  dispatch(reqFilters(reqFilter));
  dispatch(setLoader(true))
  const response = await clientService.post('report-pto-request', reqFilter)
  dispatch(setPTORequestData(response.data.data))
}
export const reportPTOReqCSV = (reqFilter) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("report-pto-request/csv",reqFilter);
  dispatch(setPTOReqCsvExportData(response.data.data));
}
export const getPTOStatementData = (stateFilter) => async (dispatch) => {
  dispatch(stateFilters(stateFilter));
  dispatch(setLoader(true))
  const response = await clientService.post('report-pto-statement', stateFilter)
  dispatch(setPTOStatementData(response.data.data))
}
export const reportPTOStateCSV = (reqFilter) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("report-pto-statement/csv",reqFilter);
  dispatch(setPTOStateCsvExportData(response.data.data));
}
export const getBalanceSheetData = (data) => async (dispatch) => {
    dispatch(setBsFilter(data))
    dispatch(setLoader(true))
    const response = await clientService.post('report-balance-sheet', data)
    dispatch(setBalanceSheet(response.data.data))
}
export const getCSVBalanceSheetData = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("report-balance-sheet/csv", data);
    if(response?.data) {
        var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, "balance_sheet_csv.csv");
    }
    dispatch(setLoader(false));
};
export const getHoursByPPData = (data) => async (dispatch) => {
    dispatch(setHsBPPFilter(data))
    dispatch(setLoader(true))
    const response = await clientService.post('report-hours-by-pp', data)
    dispatch(setHsBPPData(response.data.data))
}
export const getCSVHoursByPPData = (data) => async (dispatch) => {
    dispatch(setLoader(true));
    const response = await clientService.post("report-hours-by-pp/csv", data);
    if(response?.data) {
        var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
        FileSaver.saveAs(blob, "hours_by_pay_period_csv.csv");
    }
    dispatch(setLoader(false));
};
export const getEmployeeHourlyReportData = (data) => async (dispatch) => {
  dispatch(setEmployeeHourlyFilter(data))
  dispatch(setLoader(true))
  const response = await clientService.post('report-employee-hourly', data)
  dispatch(setEmployeeHourlyReportData(response.data.data))
};
export const getCsvEmployeeHourlyReportData = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("report-employee-hourly/csv", data);
  if(response?.data) {
      var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
      FileSaver.saveAs(blob, "All_hourly_employees_csv.csv");
  }
  dispatch(setLoader(false));
};
export const getLookBranchSummaryData = (lookFilter) => async (dispatch) => {
  if (listApiController) {
      listApiController.abort()
  }
  listApiController = new AbortController();
  dispatch(lookFilters(lookFilter));
  dispatch(setLoader(true))
  const response = await clientService.post('report-look-branch-summary', lookFilter, {signal:  listApiController.signal})
  if (response && response.data && response.data.data) {
      dispatch(setLookBranchSummaryData(response.data.data))
  }
};
export const reportLookBranchSummaryCSV = (data) => async (dispatch) => {
  dispatch(setLoader(true));
  const response = await clientService.post("report-look-branch-summary/csv", data);
  if(response?.data) {
      var blob = new Blob([response.data], {type: "text/csv;charset=utf-8"});
      FileSaver.saveAs(blob, "look_ahead_branch_summary.csv");
  }
  dispatch(setLoader(false));
};
export const lookAheadSummaryHide = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('report-look-branch-summary/makeHide', data)
  dispatch(setLookSummaryHide(response.data));
}

export const sendReminderData = (data) => async (dispatch) => {
  dispatch(setLoader(true))
  const response = await clientService.post('report-look-branch-summary/sendReminder', data)
  dispatch(setSendReminderData(response.data));
}

export const {
  filters,
  setLoader,
  setNotes,
  setReportList,
  setReportPID,
  setReportUsage,
  setCsvExportData,
  setPIDCsvExportData,
  setReportReceiving,
  setReceivingCsvExportData,
  avaFilters,
  setPTOAvailableData,
  setPTOAvaCsvExportData,
  reqFilters,
  setPTORequestData,
  setPTOReqCsvExportData,
  stateFilters,
  setPTOStatementData,
  setPTOStateCsvExportData,
  setBsFilter,
  setBalanceSheet,
  setHsBPPData,
  setHsBPPFilter,
  setEmployeeHourlyFilter,
  setEmployeeHourlyReportData,
  setSetuplist,
  lookFilters,
  setLookBranchSummaryData,
  setLookSummaryHide,
  setSendReminderData
} = reportsSlice.actions

export const reportsState = (state) => {
  return state.reports
}

export default reportsSlice.reducer
